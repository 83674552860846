
.f7kda3a{width:100%;height:60px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:fixed;top:0;left:0;right:0;z-index:100;background:var(--gh-sys-surface-onboarding-background);color:var(--gh-sys-surface-onboarding-contrast);padding:0 24px;}
.h8ccfus{width:100%;min-height:85vh;position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:var(--gh-sys-surface-onboarding-background);color:var(--gh-sys-surface-onboarding-contrast);}
.h106pxa3{position:absolute;top:0;right:0;bottom:0;left:0;background-image:url('/landingpage/hero-pattern.png');background-repeat:repeat;background-size:20%;opacity:0.05;}
.c1erb14a{width:100%;max-width:80rem;z-index:1;}
.n1xlx0w3.c1erb14a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.hdz7ps6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.h1pkrefq{-webkit-flex:1;-ms-flex:1;flex:1;padding-top:8rem;}
.hl2kr78{max-width:40rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:24px;}
.ha906bh{position:relative;-webkit-flex:1;-ms-flex:1;flex:1;}
.hj43yo8{font-size:2.5rem;margin:0;padding:0;}
.hi5ubat{font-size:1.25rem;line-height:1.5;margin:0;padding:0;}
.s14e23pj{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:32px;padding:40px 0;background:#222;color:#fff;}
.fyn5bu4.c1erb14a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;gap:24px;}
.s1g5oz5p{max-width:23rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border:2px solid #000;border-radius:16px;padding:12px;gap:12px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:var(--gh-sys-surface-onboarding-background);}
.q2t7c1s{-webkit-flex:1;-ms-flex:1;flex:1;border-radius:12px;}
.b1u2kk2f{-webkit-flex:2;-ms-flex:2;flex:2;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:12px;}
.fjo2b63{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:80px 0;}
.fqkdsbz{display:grid;grid-template-columns:1fr 1fr;grid-gap:80px;}
.sbtmr3p{text-align:center;}
.f14co30x{max-width:20rem;margin:0 auto;margin-top:16px;}
.f1puiejr{position:relative;margin-top:32px;}
.ihopqfm{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%);width:60%;aspect-ratio:1/1;background:var(--gh-sys-surface-onboarding-background);border-radius:8px;}
.s1eq9clu{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:24px;}.s1eq9clu *{cursor:pointer;}
